import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  daysTimes: [
    {
      day: "شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
    {
      day: " یەک شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
    {
      day: " دوو شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
    {
      day: " سێ شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
    {
      day: " چوار شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
    {
      day: " پێنج شەمە",
      time: " 09:30 AM - 7:00 PM ",
    },
  ],
};

export const workTimeSlice = createSlice({
  name: "days",
  initialState,
  reducers: {},
});

export const workTimeActions = workTimeSlice.actions;

export default workTimeSlice.reducer;
