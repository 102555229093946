import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Contactus from "./pages/contactus/Contactus";
import Aboutus from "./pages/aboutus/Aboutus";
import Branches from "./pages/branches/Branches";
import NoPage from "./pages/NoPage";
import Agents from "./pages/agents/Agents";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const App = () => {
  const { t, i18n } = useTranslation("common");
  useEffect(() => {
    document.body.dir = localStorage.getItem("dir");
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [localStorage.getItem("dir")]);
  return (
    <div className="">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/branches" element={<Branches />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/contacts" element={<Contactus />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
};

export default App;
