import { configureStore } from "@reduxjs/toolkit";
import navSlice from "./navSlice";
import branchSlice from "./branchSlice";
import workTimeSlice from "./workTimeSlice";

export const store = configureStore({
  reducer: {
    nav: navSlice,
    branch: branchSlice,
    workTime: workTimeSlice,
  },
});
