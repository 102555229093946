import companyLogo from "../assets/images/logo.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavbarItems from "./NavbarItems";

const Navbar = () => {
  const { t, i18n } = useTranslation("common");
  const [navbarVisible, setNavbarVisible] = useState(false);

  const changeLanguage = (lang) => {
    localStorage.setItem("dir", t("dir"));
    localStorage.setItem("lang", t(lang));
  };

  const navbarSizehandle = () => {
    setNavbarVisible(!navbarVisible);
  };
  return (
    <nav
      className=" flex justify-between lg:flex-row-reverse md:flex-col sm:flex-col max-sm:flex-col
     bg-nili items-center shadow-lg font-Rabar_015  "
    >
      {/* Menu */}
      <div className=" w-svw  flex justify-between lg:justify-start lg:gap-52 flex-row-reverse items-cente ">
        <div className=" p-2 flex flex-row-reverse justify-between  items-center gap-1 ">
          <img
            src={companyLogo}
            alt="haji hussin logo"
            className="h-10 w-10 "
          />

          <h1 className=" text-gold text-lg ">{t("nav.header")}</h1>
        </div>
        {/* languages */}

        <ul className=" p-2 lg:ml-10 flex gap-4 lg:flex-row-reverse justify-items-center items-center text-white ">
          <li
            className="hover:text-gold w-max cursor-pointer"
            onClick={() => {
              i18n.changeLanguage("ku");
              changeLanguage("ku");
            }}
          >
            Ku
          </li>
          <li
            className="hover:text-gold w-max cursor-pointer"
            onClick={() => {
              i18n.changeLanguage("ar");
              changeLanguage("ar");
            }}
          >
            Ar
          </li>
          <li
            className="hover:text-gold w-max cursor-pointer"
            onClick={() => {
              i18n.changeLanguage("en");
              changeLanguage("en");
            }}
          >
            En
          </li>
        </ul>

        <div className=" lg:hidden flex items-center justify-center p-2">
          <button
            className="flex items-center px-3 py-2 border rounded text-gold border-gold hover:text-white hover:border-white"
            onClick={navbarSizehandle}
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
      </div>

      {/* Nav Link */}
      <div
        className={
          navbarVisible === false
            ? " lg:block md:hidden sm:hidden max-sm:hidden"
            : "block"
        }
      >
        <ul className=" p-2 lg:ml-10 flex gap-4 lg:flex-row-reverse justify-items-center items-center text-gold md:flex-col sm:flex-col max-sm:flex-col">
          <NavbarItems onClick={navbarSizehandle} />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
