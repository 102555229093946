import { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const LeafletMap = (props) => {
  const customIcon = new Icon({
    iconUrl: require("../assets/images/mappin.png"),
    iconSize: [30, 30],
  });
  const position = [36.20380256428323, 44.01019717510437];

  return (
    <div className=" h-[50svh] lg:w-[50svw] md:w-[70svw] sm:w-[90svw] max-sm:w-[96svw] flex flex-col justify-center  mx-auto mb-10 ">
      <MapContainer
        center={position}
        zoom={11}
        style={{ height: "100vh", width: "100%" }}
        className=" rounded-lg ap-container"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {props.branches.map((branch) => (
          <Marker position={branch.address.position} icon={customIcon}>
            <Popup>
              <a href={branch.address.linkMap} target="_blank">
                {branch.branchName}
              </a>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
