import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";

const Home = () => {
  const { t } = useTranslation("common");

  return (
    <MainLayout>
      <div className="flex flex-col items-center">
        <div
          className="h-[94svh] w-svw lg:bg-homebackground md:bg-homebackground sm:bg-homebackgroundM max-sm:bg-homebackgroundM 
       bg-no-repeat bg-cover bg-center flex flex-row justify-center "
        >
          <main className="h-[20svh] mt-14 bg-slate-500 bg-opacity-35 text-gold rounded-xl flex items-center ">
            <header className=" font-Rabar_015 lg:text-6xl p-10 md:text-4xl sm:text-2xl max-sm:text-lg  transition ">
              {t("homePage.companyNameHeader")}
            </header>
          </main>
        </div>
        <div className=" h-36"></div>
        <div
          className=" lg:h-40 md:h-40 w-[90svw] bg-black relative -mt-32 rounded-xl font-Rabar_015 text-white 
      flex lg:flex-row md:flex-row sm:flex-col max-sm:flex-col justify-around items-center gap-5 p-2
       bg-opacity-35 shadow-2xl "
        >
          <div>
            <h1>{t("homePage.divFooter.1")}</h1>
          </div>
          <div>
            <h1>{t("homePage.divFooter.2")}</h1>
          </div>
          <div>
            <h1>{t("homePage.divFooter.3")}</h1>
          </div>
          <div>
            <h1>{t("homePage.divFooter.4")}</h1>
          </div>
        </div>

        <div
          className=" mb-20 h-[50svh] w-[100svw] rounded-lg bg-bbb1 
                  bg-no-repeat bg-cover bg-center  flex justify-center items-center bg-fixed "
        >
          <article className="  font-Rabar_015 ">
            <h1 className=" text-gold text-center lg:text-4xl md:text-4xl  text-4xl">
              باشترین ئۆفەركان لە
              <span className="lg:text-5xl md:text-5xl  text-4xl text-nili block p-2">
                کۆمپانیای عقارات حاجی حسین
              </span>
              بەردەست بكە
            </h1>
          </article>
        </div>
      </div>
      <div></div>
    </MainLayout>
  );
};

export default Home;
