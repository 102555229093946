import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarItems = (props) => {
  const location = useLocation();
  const { t } = useTranslation("common");
  const items = [
    {
      name: t("nav.navLink.home"),
      href: "/",
    },
    {
      name: t("nav.navLink.services"),
      href: "/services",
    },
    {
      name: t("nav.navLink.branches"),
      href: "/branches",
    },
    {
      name: t("nav.navLink.aboutus"),
      href: "/aboutus",
    },
    {
      name: t("nav.navLink.agents"),
      href: "/agents",
    },
    {
      name: t("nav.navLink.contacts"),
      href: "/contacts",
    },
  ];
  return (
    <>
      {items.map((item) => (
        <li className="hover:text-white w-max">
          <NavLink
            to={item.href}
            onClick={props.onClick}
            className={
              location.pathname === item.href ? " border-b-2 text-white " : ""
            }
          >
            {item.name}
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default NavbarItems;
