import MainLayout from "../../layouts/MainLayout";

const Services = () => {
  return (
    <MainLayout>
      <div>Services</div>
    </MainLayout>
  );
};

export default Services;
