import React from "react";
import { Image } from "antd";
import LeafletMap from "../../components/LeafletMap";
import { useTranslation } from "react-i18next";

const BranchItems = () => {
  const { t } = useTranslation("common");
  const items = [
    {
      id: 1,
      branchName: t("branchesPage.branches.management.name"),
      phone: " 0750 261 14444 - 0750 202 1444 ",
      address: {
        name: t("branchesPage.branches.management.address"),
        linkMap:
          "https://www.google.com/maps/place/Haji+Hussin+Real+Estate/@36.1870507,43.9646277,19.25z/data=!4m6!3m5!1s0x400723d059d52433:0x66e0316cd2a70294!8m2!3d36.1872166!4d43.9645624!16s%2Fg%2F11tf97yd8g?authuser=0&entry=ttu",
        position: [36.18723807927963, 43.96455402286152],
      },
      image: require("../../assets/images/branches/management.jpg"),
    },
    {
      id: 2,
      branchName: t("branchesPage.branches.ramiTowers.name"),
      phone: " 0750 189 14444 - 0750 280 1444 ",
      address: {
        name: t("branchesPage.branches.ramiTowers.address"),
        linkMap:
          "https://www.google.com/maps/place/%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA+%D8%AD%D8%A7%D8%AC%D9%8A+%D8%AD%D8%B3%D9%8A%D9%86%E2%80%AD/@36.1890557,43.9653638,18.25z/data=!4m6!3m5!1s0x400723165b21341f:0xee9d0583fcc95616!8m2!3d36.1897331!4d43.9671436!16s%2Fg%2F11t2b_h5gt?authuser=0&entry=ttu",
        position: [36.18974471413793, 43.96714583098408],
      },
      image: require("../../assets/images/branches/rami.JPG"),
    },
    {
      id: 3,
      branchName: t("branchesPage.branches.pearl.name"),
      phone: " 0750 297 14444 - 0750 900 1444 ",
      address: {
        name: t("branchesPage.branches.pearl.address"),
        linkMap:
          "https://www.google.com/maps/place/%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA+%D8%AD%D8%A7%D8%AC%DB%8C+%D8%AD%D8%B3%DB%8C%D9%86+%D9%80+%D9%81%D8%B1%D8%B9+%D9%BE%DB%8E%DA%95%DA%B5%E2%80%AD/@36.1993476,43.9644814,19.25z/data=!4m6!3m5!1s0x40072323c07b5f99:0x8b4692655a1b81f0!8m2!3d36.1991424!4d43.9645759!16s%2Fg%2F11vhlj04z9?authuser=0&entry=ttu",
        position: [36.19918461946724, 43.96458342418031],
      },
      image: require("../../assets/images/branches/rami.JPG"),
    },
    {
      id: 4,
      branchName: t("branchesPage.branches.itali2.name"),
      phone: " 0750 299 14444 - 0750 291 1444 ",
      address: {
        name: t("branchesPage.branches.itali2.address"),
        linkMap:
          "https://www.google.com/maps/place/Haji+Hussin+Real+Estate/@36.239902,44.0500271,18.75z/data=!4m14!1m7!3m6!1s0x40071f9615f74017:0x9ebfbafe35a9d99d!2sHaji+hussin+Real+Estate!8m2!3d36.2595625!4d44.0136875!16s%2Fg%2F11s5nqgdlr!3m5!1s0x400721d328bdc14b:0x267ec91b0e563869!8m2!3d36.2394894!4d44.0497297!16s%2Fg%2F11rglk55j6!5m1!1e4?authuser=0&entry=ttu",
        position: [36.239540283621814, 44.04971886587235],
      },
      image: require("../../assets/images/branches/itali2.JPG"),
    },

    {
      id: 5,
      branchName: t("branchesPage.branches.lubnani.name"),
      phone: " 0750 282 14444 - 0750 591 1444 ",
      address: {
        name: t("branchesPage.branches.lubnani.address"),
        linkMap:
          "https://www.google.com/maps/place/Haji+hussin+Real+Estate/@36.2610877,44.0148384,16.5z/data=!4m6!3m5!1s0x40071f9615f74017:0x9ebfbafe35a9d99d!8m2!3d36.2595625!4d44.0136875!16s%2Fg%2F11s5nqgdlr!5m1!1e4?authuser=0&entry=ttu",
        position: [36.25977859592266, 44.013730804128315],
      },
      image: require("../../assets/images/branches/lubnani.JPG"),
    },
    {
      id: 6,
      branchName: t("branchesPage.branches.mamostaian.name"),
      phone: " 0750 252 14444 - 0750 185 1444 ",
      address: {
        name: t("branchesPage.branches.mamostaian.address"),
        linkMap:
          "https://www.google.com/maps/place/Haji+Hussin+Real+Estate/@36.2711754,44.0835286,15z/data=!4m2!3m1!1s0x0:0x946eaab8aea7de17?sa=X&ved=1t:2428&hl=en&ictx=111",
        position: [36.27204038186371, 44.083657346030165],
      },
      image: require("../../assets/images/branches/mamostaian.JPG"),
    },
    {
      id: 7,
      branchName: t("branchesPage.branches.kory.name"),
      phone: " 0750 282 14444 - 0750 911 1444 ",
      address: {
        name: t("branchesPage.branches.kory.address"),
        linkMap:
          "https://www.google.com/maps/place/Haji+Hussin+Real+Estate/@36.2829375,44.0703124,15z/data=!4m6!3m5!1s0x4006e1c9de77ba19:0xa0dda9d8abc3b8c9!8m2!3d36.2829375!4d44.0703124!16s%2Fg%2F11t82jt81k?hl=en&entry=ttu",
        position: [36.28345641207621, 44.07026948465661],
      },
      image: require("../../assets/images/branches/kory.JPG"),
    },
    {
      id: 8,
      branchName: t("branchesPage.branches.hawlerNew.name"),
      phone: " 0750 250 14444 - 0750 179 1444 ",
      address: {
        name: t("branchesPage.branches.hawlerNew.address"),
        linkMap:
          "https://www.google.com/maps/place/%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA+%D8%AD%D8%A7%D8%AC%D9%89+%D8%AD%D8%B3%D9%8A%D9%86+(haji+hussin+real+estate)%E2%80%AD/@36.2188508,44.0653876,18.25z/data=!4m14!1m7!3m6!1s0x400721d328bdc14b:0x267ec91b0e563869!2sHaji+Hussin+Real+Estate!8m2!3d36.2403075!4d44.0498971!16s%2Fg%2F11rglk55j6!3m5!1s0x400721f0b90d79d1:0x7fb6fd3da430b599!8m2!3d36.2186565!4d44.0659863!16s%2Fg%2F11l5f7_3g5!5m1!1e4?authuser=0&entry=ttu",
        position: [36.21871610821506, 44.0659966135133],
      },
      image: require("../../assets/images/branches/rami.JPG"),
    },
    {
      id: 9,
      branchName: t("branchesPage.branches.majdiView.name"),
      phone: " 0750 203 14444 - 0750 203 1444 ",
      address: {
        name: t("branchesPage.branches.majdiView.address"),
        linkMap:
          "https://www.google.com/maps/place/36%C2%B012'03.8%22N+44%C2%B005'13.3%22E/@36.201042,44.0863873,19z/data=!3m1!4b1!4m4!3m3!8m2!3d36.201042!4d44.087031!5m1!1e4?authuser=0&entry=ttu",
        position: [36.2010701355673, 44.08702831754522],
      },
      image: require("../../assets/images/branches/rami.JPG"),
    },
    {
      id: 10,
      branchName: t("branchesPage.branches.floria.name"),
      phone: " 0750 296 14444 - 0750 295 1444 ",
      address: {
        name: t("branchesPage.branches.floria.address"),
        linkMap:
          "https://www.google.com/maps/place/%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA+%D8%AD%D8%A7%D8%AC%DB%8C+%D8%AD%D8%B3%DB%8C%D9%86+-+%D9%81%D9%84%DB%86%D8%B1%DB%8C%DB%95+%D8%B3%D8%AA%DB%8C%E2%80%AD/@36.2024816,44.1060776,17.5z/data=!4m14!1m7!3m6!1s0x400721a467348645:0x2e919f9e2b0788bd!2z2LnZgtin2LHYp9iqINit2KfYrNuMINit2LPbjNmGIC0g2YHZhNuG2LHbjNuVINiz2KrbjA!8m2!3d36.2026033!4d44.1078452!16s%2Fg%2F11l5zd874j!3m5!1s0x400721a467348645:0x2e919f9e2b0788bd!8m2!3d36.2026033!4d44.1078452!16s%2Fg%2F11l5zd874j!5m1!1e4?authuser=0&entry=ttu",
        position: [36.20262848023475, 44.10788317262092],
      },
      image: require("../../assets/images/branches/rami.JPG"),
    },
    {
      id: 11,
      branchName: t("branchesPage.branches.pakland.name"),
      phone: " 0750 238 14444 - 0750 271 1333 ",
      address: {
        name: t("branchesPage.branches.pakland.address"),
        linkMap:
          "https://www.google.com/maps/place/%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA+%D8%AD%D8%A7%D8%AC%DB%8C+%D8%AD%D8%B3%DB%8C%D9%86+-+%D9%81%D8%B1%D8%B9+%D8%A8%D8%A7%DA%A9%D9%84%D8%A7%D9%86%D8%AF%E2%80%AD/@36.2133556,44.1305471,15.5z/data=!4m14!1m7!3m6!1s0x400727003a890bf5:0xca75327ba24f3197!2z2LnZgtin2LHYp9iqINit2KfYrNuMINit2LPbjNmGIC0g2YHYsdi5INio2KfaqdmE2KfZhtiv!8m2!3d36.2143102!4d44.1380262!16s%2Fg%2F11y24zc4th!3m5!1s0x400727003a890bf5:0xca75327ba24f3197!8m2!3d36.2143102!4d44.1380262!16s%2Fg%2F11y24zc4th!5m1!1e4?authuser=0&entry=ttu",
        position: [36.214849125109914, 44.138163830309374],
      },
      image: require("../../assets/images/branches/pakland.JPG"),
    },
  ];

  return (
    <>
      <div className=" w-svw flex flex-row-reverse flex-wrap gap-10 justify-center items-center max-sm:flex-col mb-10  ">
        {items.map((item) => (
          <div
            className="  font-Rabar_015 lg:w-[30svw] lg:min-w-[30svw] md:w-[45svw] md:min-w-[45svw] sm:w-[92svw] max-sm:w-[92svw]
         bg-white rounded-md flex flex-col gap-2 "
          >
            <div>
              <Image
                src={item.image}
                className=" rounded-md -z-10"
                height={300}
                width="100%"
              />
            </div>
            <div className="z-1 -mt-10 relative bg-white rounded-t-md pt-3">
              <h1 className=" text-gold text-center">{item.branchName} </h1>
            </div>
            <div>
              <p className=" text-center" dir="ltr">
                {item.phone}{" "}
              </p>
            </div>
            <div>
              <a href={item.address.linkMap} target="_blank">
                <p className=" text-center flex justify-center">
                  {item.address.name}
                  <span className=" text-gold pb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </p>
              </a>
            </div>
            <div className=" flex justify-center">
              <a
                dir="ltr"
                href={item.address.linkMap}
                target="_blank"
                className=" bg-gold text-nili p-2  pl-7 pr-7 
            rounded-lg mb-3 shadow-lg shadow-indigo-500/40 hover:bg-nili hover:text-gold flex flex-nowrap 
             active:bg-nili active:text-gold "
              >
                View Map
                <span className=" ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        ))}
      </div>
      <LeafletMap branches={items} />
    </>
  );
};

export default BranchItems;
