import React from "react";
import MainLayout from "../../layouts/MainLayout";

const Aboutus = () => {
  return (
    <MainLayout>
      <div className=" bg-nili h-svh">
        <div className=" h-52 bg-nili w-svw flex justify-center items-center ">
          <div className=" h-2/3 w-1/3 bg-orange-500 rounded-lg flex justify-center items-center">
            <h1 className=" font-Rabar_015 text-4xl ">About Us</h1>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Aboutus;
