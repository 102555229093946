import React from "react";

const NoPage = () => {
  return (
    <div className="h-[80svh] flex justify-center items-center">
      <h1 className="text-4xl text-gold">Page Not Found!</h1>
    </div>
  );
};

export default NoPage;
