import MainLayout from "../../layouts/MainLayout";

const Contactus = () => {
  return (
    <MainLayout>
      <div>Contactus</div>
    </MainLayout>
  );
};

export default Contactus;
