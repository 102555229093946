import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [
    {
      name: "سەرەکی",
      href: "/",
    },
    {
      name: "خزمەتگوزاریەکان",
      href: "services",
    },
    {
      name: "لقەکان",
      href: "branches",
    },
    {
      name: "ئێمە",
      href: "aboutus",
    },
    {
      name: "بریکارەکان",
      href: "agents",
    },
    {
      name: "پەیوەندیکردن",
      href: "contacts",
    },
  ],
};

export const navSlice = createSlice({
  name: "navbarItems",
  initialState,
  reducers: {
    navItemsUpdate: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const navItemsUpdate = navSlice.actions;

export default navSlice.reducer;
