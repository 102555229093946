import React from "react";
import companyLogo from "../assets/images/english_logo_BRONZE.png";
import NavbarItems from "./NavbarItems";
import { useTranslation } from "react-i18next";
import {
  FacebookFilled,
  InstagramFilled,
  YoutubeFilled,
} from "@ant-design/icons";
const Footer = () => {
  const { t } = useTranslation("common");

  const workeTime = [
    {
      day: t("footer.workTime.sa.name"),
      time: t("footer.workTime.sa.time"),
    },
    {
      day: t("footer.workTime.su.name"),
      time: t("footer.workTime.su.time"),
    },
    {
      day: t("footer.workTime.mo.name"),
      time: t("footer.workTime.mo.time"),
    },
    {
      day: t("footer.workTime.tu.name"),
      time: t("footer.workTime.tu.time"),
    },
    {
      day: t("footer.workTime.we.name"),
      time: t("footer.workTime.we.time"),
    },
    {
      day: t("footer.workTime.th.name"),
      time: t("footer.workTime.th.time"),
    },
  ];
  const branches = [
    {
      id: 1,
      branchName: t("branchesPage.branches.management.name"),
    },
    {
      id: 2,
      branchName: t("branchesPage.branches.ramiTowers.name"),
    },
    {
      id: 3,
      branchName: t("branchesPage.branches.pearl.name"),
    },
    {
      id: 4,
      branchName: t("branchesPage.branches.itali2.name"),
    },

    {
      id: 5,
      branchName: t("branchesPage.branches.lubnani.name"),
    },
    {
      id: 6,
      branchName: t("branchesPage.branches.mamostaian.name"),
    },
    {
      id: 7,
      branchName: t("branchesPage.branches.kory.name"),
    },
    {
      id: 8,
      branchName: t("branchesPage.branches.hawlerNew.name"),
    },
    {
      id: 9,
      branchName: t("branchesPage.branches.majdiView.name"),
    },
    {
      id: 10,
      branchName: t("branchesPage.branches.floria.name"),
    },
    {
      id: 11,
      branchName: t("branchesPage.branches.pakland.name"),
    },
  ];

  return (
    <>
      <footer className=" p-5 bg-footercolor font-Rabar_015 w-svw ">
        <div className="flex flex-wrap justify-around lg:flex-row-reverse md:flex-row-reverse sm:flex-col max-sm:flex-col mt-10">
          <div className=" flex flex-col justify-center items-center gap-2 max-sm:border-b-2 pb-2 mb-2">
            <h1 className=" text-center text-white text-lg ">
              {t("footer.companyName")}
            </h1>
            <img src={companyLogo} alt="haji hussin logo" className="h-52 " />
            <p className=" flex gap-2">
              <a
                href="tel:+9647502281444"
                className=" text-center text-white text-lg "
                dir="ltr"
              >
                0750 228 1444
              </a>
              <span className=" text-gold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </p>
            <p className=" flex gap-2 items-center">
              <a
                href="https://www.google.com/maps/place/Haji+Hussin+Real+Estate/@36.1871919,43.9646123,21z/data=!4m6!3m5!1s0x400723d059d52433:0x66e0316cd2a70294!8m2!3d36.1872166!4d43.9645624!16s%2Fg%2F11tf97yd8g?entry=ttu"
                target="_blank"
                className=" text-center text-white text-lg hover:text-gold"
              >
                {t("footer.companyAddress.1")} <br />{" "}
                {t("footer.companyAddress.2")}
              </a>
              <span className=" text-gold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </p>

            <p className=" flex gap-2">
              <h1 className=" text-center text-white text-lg ">
                info@hajihussin.com
              </h1>
              <span className=" text-gold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>
              </span>
            </p>

            <div className=" flex flex-row gap-2">
              <a
                href="https://www.facebook.com/HajiHussinRealestateCompany"
                target="_blank"
              >
                <FacebookFilled
                  style={{
                    fontSize: "28px",
                    color: "#08c",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/haji.hussin.realestate"
                target="_blank"
              >
                <InstagramFilled
                  style={{ fontSize: "28px", color: "#C13584" }}
                />
              </a>
              <a
                href="https://www.youtube.com/@HajiHussinRealestateCompany"
                target="_blank"
              >
                <YoutubeFilled
                  style={{
                    fontSize: "30px",
                    color: "red",
                  }}
                />
              </a>
            </div>
          </div>
          <div className="max-sm:border-b-2 pb-2 mb-2 flex-col">
            <h1 className=" text-center text-white text-lg">{t("nav.link")}</h1>
            <ul className=" p-2 flex gap-4 flex-col justify-items-center items-center text-gold">
              <NavbarItems />
            </ul>
          </div>
          <div className="max-sm:border-b-2 pb-2 mb-2">
            <h1 className=" text-center text-white text-lg ">
              {t("branchesPage.header")}
            </h1>
            <ul className=" p-2 flex gap-4 flex-col justify-items-center items-center text-gold">
              {branches.map((item) => (
                <li className="">
                  <p>{item.branchName}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className=" pb-2 mb-2">
            <h1 className=" text-center text-white text-lg">
              {t("footer.workTime.header")}
            </h1>
            <ul className=" p-2 flex gap-4 lg:flex-col justify-items-center items-center text-gold md:flex-col sm:flex-col max-sm:flex-col">
              {workeTime.map((item) => (
                <li className="">
                  <h1 className=" text-center">
                    <p>{item.day}</p> <p dir="ltr">{item.time}</p>
                  </h1>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <hr />

          <h1 className=" text-cyan-700 text-center m-2 text-lg">
            @Created By Xom
          </h1>
        </div>
      </footer>
    </>
  );
};

export default Footer;
