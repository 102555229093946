import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branchItems: [
    {
      name: "لقی سەرەکی",
      href: "/",
    },
    {
      name: "لقی رامی تاوەرس",
      href: "services",
    },
    {
      name: "لقی گوندی لوبنانی",
      href: "branches",
    },
    {
      name: "لقی ئیاڵی 2",
      href: "aboutus",
    },
    {
      name: "لقی هەولێری نوێ",
      href: "agents",
    },
    {
      name: "لقی ئێمپایەر پێڕڵ",
      href: "contacts",
    },
    {
      name: "لقی  مامۆستایان",
      href: "contacts",
    },
    {
      name: "لقی گوندی کۆری",
      href: "contacts",
    },
    {
      name: "لقی ماجدی ڤیو",
      href: "contacts",
    },
    {
      name: "لقی فلۆریا ",
      href: "contacts",
    },
    {
      name: "لقی پاکلاند ستی ",
      href: "contacts",
    },
  ],
};

export const branchSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
});

export const branchActions = branchSlice.actions;

export default branchSlice.reducer;
