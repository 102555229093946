import React from "react";
import BranchItems from "./BranchItems";
import MainLayout from "../../layouts/MainLayout";
import { useTranslation } from "react-i18next";

const Branches = () => {
  const { t } = useTranslation("common");
  return (
    <MainLayout>
      <div className="flex flex-col justify-center items-center">
        <div
          className=" mb-20 h-[55svh] w-[100svw] rounded-lg lg:bg-branchbackground md:bg-branchbackground sm:bg-branchbackground max-sm:bg-branchbackground 
                  bg-no-repeat bg-cover bg-center flex justify-center items-center bg-fixed "
        >
          <div className="bg-black bg-opacity-50 relative h-2/4 w-2/4  rounded-md font-Rabar_015 flex justify-center items-center shadow-slate-100 ">
            <h1 className=" text-slate-300 lg:text-6xl md:text-6xl  text-4xl ">
              {t("branchesPage.header")}
            </h1>
          </div>
        </div>

        <BranchItems />
      </div>
    </MainLayout>
  );
};

export default Branches;
